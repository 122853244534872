var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "700", persistent: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-toolbar",
            { staticClass: "headline green darken-2 white--text" },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-space-between": "" } },
                [
                  _c("span", [
                    _vm._v("\n          Dados da Empresa\n        "),
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { flat: "", icon: "" }, on: { click: _vm.close } },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { width: "700" } },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-lg": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("Nome:")]),
                        _c("span", [_vm._v(_vm._s(_vm.selectedCompany.name))]),
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("CPF/CNPJ:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formatCpfCnpj(_vm.selectedCompany.cpf_cnpj)
                            )
                          ),
                        ]),
                      ]),
                      _vm.selectedCompany.cellphone
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Celular:")]),
                            _c("span", [_vm._v(_vm._s(_vm.cellphone))]),
                          ])
                        : _vm._e(),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("Endereço:")]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.address) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-divider", { staticClass: "mb-2" }),
                          _c("h3", [_vm._v("Perfil:")]),
                          _c(
                            "v-layout",
                            {
                              staticClass: "mx-2",
                              attrs: { row: "", wrap: "" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: "Gerador",
                                  value: "generator",
                                },
                                model: {
                                  value: _vm.selectedCompany.profile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedCompany,
                                      "profile",
                                      $$v
                                    )
                                  },
                                  expression: "selectedCompany.profile",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: "Destinador",
                                  value: "receiver",
                                },
                                model: {
                                  value: _vm.selectedCompany.profile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedCompany,
                                      "profile",
                                      $$v
                                    )
                                  },
                                  expression: "selectedCompany.profile",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: "Transportador",
                                  value: "transporter",
                                },
                                model: {
                                  value: _vm.selectedCompany.profile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedCompany,
                                      "profile",
                                      $$v
                                    )
                                  },
                                  expression: "selectedCompany.profile",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: "Armazenador Temporário",
                                  value: "temporary_storage",
                                },
                                model: {
                                  value: _vm.selectedCompany.profile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedCompany,
                                      "profile",
                                      $$v
                                    )
                                  },
                                  expression: "selectedCompany.profile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "px-4" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { flat: "" }, on: { click: _vm.close } },
                        [_vm._v("\n            Cancelar\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("\n            Salvar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }