var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { "grid-list-md": "" } },
            [
              _vm.showCompanyDialog
                ? _c("company-dialog", {
                    attrs: {
                      show: _vm.showCompanyDialog,
                      selectedCompany: _vm.selectedCompany,
                    },
                    on: { close: _vm.closeCompanyDialog },
                  })
                : _vm._e(),
              _vm.showCompanyRequestDialog
                ? _c("company-request-dialog", {
                    attrs: {
                      show: _vm.showCompanyRequestDialog,
                      selectedCompany: _vm.selectedCompany,
                      accept: _vm.accept,
                    },
                    on: {
                      close: _vm.closeCompanyRequestDialog,
                      updateList: _vm.getPendingCompanies,
                    },
                  })
                : _vm._e(),
              _c(
                "v-layout",
                { staticClass: "ma-2", attrs: { column: "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mb-4",
                      staticStyle: { cursor: "pointer" },
                      attrs: { tag: "span", to: { name: "Configurações" } },
                    },
                    [
                      _c("v-icon", { attrs: { size: "18" } }, [
                        _vm._v("mdi-chevron-left"),
                      ]),
                      _vm._v("\n          Voltar para configurações\n        "),
                    ],
                    1
                  ),
                  _c("h1", [_vm._v("Empresas pendentes")]),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.pendences,
                  loading: _vm.loading,
                  "hide-actions": "",
                  "no-data-text": "Nenhuma Empresa Pendente",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.id))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.formatCpfCnpj(props.item.cpf_cnpj))
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(props.item.name))]),
                        _c(
                          "td",
                          { staticClass: "text-xs-right" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  icon: "",
                                  flat: "",
                                  title: "Visualizar",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCompany(props.item)
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-eye")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  icon: "",
                                  flat: "",
                                  title: "Rejeitar",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "red" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.companyRequest(
                                          props.item,
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-close")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  icon: "",
                                  flat: "",
                                  title: "Aprovar",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.companyRequest(
                                          props.item,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-check")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }