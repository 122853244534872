<template>
  <section>
    <v-dialog
      v-model="show"
      width="700"
      persistent
    >
      <v-toolbar class="headline green darken-2 white--text">
        <v-layout align-center justify-space-between>
          <span>
            Dados da Empresa
          </span>
          <v-btn flat icon @click="close">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>

        </v-layout>
      </v-toolbar>
      <v-card width="700">
        <v-container grid-list-lg>
            <v-layout wrap>
              <v-flex xs12>
                <h3>Nome:</h3>
                <span>{{ selectedCompany.name }}</span>
              </v-flex>

              <v-flex xs12>
                <h3>CPF/CNPJ:</h3>
                <span>{{ formatCpfCnpj(selectedCompany.cpf_cnpj) }}</span>
              </v-flex>

              <v-flex xs12 v-if="selectedCompany.cellphone">
                <h3>Celular:</h3>
                <span>{{ cellphone }}</span>
              </v-flex>

              <v-flex xs12>
                <h3>Endereço:</h3>
                <span>
                  {{ address }}
                </span>
              </v-flex>

              <v-flex xs12>
                <v-divider class="mb-2"/>
                <h3>Perfil:</h3>
                <v-layout row class="mx-2" wrap>
                  <v-checkbox
                    color="primary"
                    v-model="selectedCompany.profile"
                    label="Gerador"
                    value="generator"
                  />
                  <v-checkbox
                    color="primary"
                    v-model="selectedCompany.profile"
                    label="Destinador"
                    value="receiver"
                  />
                  <v-checkbox
                    color="primary"
                    v-model="selectedCompany.profile"
                    label="Transportador"
                    value="transporter"
                  />
                  <v-checkbox
                    color="primary"
                    v-model="selectedCompany.profile"
                    label="Armazenador Temporário"
                    value="temporary_storage"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
        </v-container>
        <v-card-actions class="px-4">
          <v-layout justify-end>
            <v-btn flat @click="close">
              Cancelar
            </v-btn>
            <v-btn color="primary" @click="save">
              Salvar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import personService from '@/services/person-service';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import formatters from '@/utils/formatters';

export default {
  name: 'CompanyDialog',
  props: ['show', 'selectedCompany'],
  data() {
    return {
      formatCpfCnpj,
      formatters,
      valid: false,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      personService
        .updatePerson(this.selectedCompany.id, this.selectedCompany)
        .then(() => {
          this.close();
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Alterações salvas com sucesso!',
            messageIcon: 'mdi-ckeck',
          });
        })
        .catch((error) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: error.message,
            messageIcon: 'mdi-exclamation',
          });
        });
    },
  },
  computed: {
    cellphone() {
      return this.formatters.filters.phone(this.selectedCompany.cellphone);
    },
    address() {
      const { address } = this.selectedCompany;
      const zipCode = this.formatters.filters.cep(address.zip_code);
      return `${address.street}, ${address.number} - ${address.neighborhood} (${zipCode})`;
    },
  },
};
</script>
