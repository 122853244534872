<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md class="px-4">

        <company-dialog
          v-if="showCompanyDialog"
          :show="showCompanyDialog"
          :selectedCompany = selectedCompany
          @close="closeCompanyDialog"
        />

        <company-request-dialog
          v-if="showCompanyRequestDialog"
          :show="showCompanyRequestDialog"
          :selectedCompany= selectedCompany
          :accept="accept"
          @close="closeCompanyRequestDialog"
          @updateList="getPendingCompanies"
        />

         <v-layout column class="ma-2">
          <router-link class="mb-4" style="cursor:pointer" tag="span" :to="{name: 'Configurações'}">
            <v-icon size="18">mdi-chevron-left</v-icon>
            Voltar para configurações
          </router-link>

          <h1>Empresas pendentes</h1>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="pendences"
          :loading="loading"
          hide-actions
          no-data-text="Nenhuma Empresa Pendente"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ formatCpfCnpj(props.item.cpf_cnpj) }}</td>
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">
              <v-btn small icon flat title="Visualizar">
                <v-icon @click="selectCompany(props.item)">mdi-eye</v-icon>
              </v-btn>
              <v-btn small icon flat title="Rejeitar">
                <v-icon @click="companyRequest(props.item, false)" color="red">mdi-close</v-icon>
              </v-btn>
              <v-btn small icon flat title="Aprovar">
                <v-icon @click="companyRequest(props.item, true)" color="green">mdi-check</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import PersonService from '@/services/person-service';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import CompanyDialog from '@/components/settings/CompanyDialog';
import CompanyRequestDialog from '@/components/settings/CompanyRequestDialog';

export default {
  name: 'PendingCompaniesList',
  components: {
    InLayout,
    PersonService,
    CompanyDialog,
    CompanyRequestDialog,
  },
  mounted() {
    this.getPendingCompanies();
  },
  data() {
    return {
      loading: false,
      pendences: [],
      formatCpfCnpj,
      selectedCompany: {},
      accept: true,
      showCompanyDialog: false,
      showCompanyRequestDialog: false,
      headers: [
        {
          text: 'ID', value: 'id', sortable: true, width: 150,
        },
        {
          text: 'CPF/CNPJ', value: 'CPF_CNPJ', align: 'left', sortable: false, width: 350,
        },
        {
          text: 'Nome', value: 'Nome', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Ações', value: 'actions', align: 'right', sortable: false, width: 200,
        },
      ],
    };
  },
  methods: {
    async edit(person) {
      this.selectedPendency = await PersonService.show(person.id);
      this.showPendencyDialog = true;
    },
    closeCompanyDialog() {
      this.showCompanyDialog = false;
    },
    closeCompanyRequestDialog() {
      this.showCompanyRequestDialog = false;
    },
    selectCompany(company) {
      this.selectedCompany = company;
      this.showCompanyDialog = true;
    },
    companyRequest(company, accept) {
      this.selectedCompany = company;
      this.showCompanyRequestDialog = true;
      this.accept = accept;
    },
    getPendingCompanies() {
      this.loading = true;
      PersonService
        .getPendingCompanies()
        .then((response) => {
          this.pendences = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
