<template>
  <section>
    <v-dialog
      v-if="accept"
      v-model="show"
      width="400"
      persistent
    >
      <v-toolbar class="headline" color="white">
        Deseja aceitar a solicitação?
      </v-toolbar>

      <v-card width="400" class="text-xs-center">
        <span>
          A empresa irá para a página "Empresas cadastradas".
        </span>
        <v-card-actions class="mt-5 px-4">
          <v-layout justify-end>
            <v-btn flat @click="close">
              Fechar
            </v-btn>
            <v-btn color="primary" @click="acceptCompany">
              Aceitar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-else
      v-model="show"
      width="450"
      persistent
    >
      <v-toolbar class="headline" color="white">
        Deseja recusar a solicitação?
      </v-toolbar>

      <v-card width="450" class="text-xs-center px-4">
        <v-textarea
          v-model="justification"
          class="ma-0"
          label="Escreva uma justificativa"
          name="justification"
          :rules="rules"
          maxlength="500"
          counter="500"
          no-resize
          filled
        />
        <v-card-actions>
          <v-layout justify-end>
            <v-btn flat @click="close">
              Fechar
            </v-btn>
            <v-btn
              color="error"
              :disabled="justification.length == 0"
              @click="refuseCompany">
              Recusar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>

</template>

<script>
import PersonService from '@/services/person-service';
import validationRules from '@/mixins/validation-rules';

export default {
  name: 'CompanyRequestDialog',
  props: ['show', 'accept', 'selectedCompany'],
  components: {
    PersonService,
  },
  data() {
    return {
      justification: '',
      rules: [validationRules.required],
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    acceptCompany() {
      PersonService.acceptCompany(this.selectedCompany.id)
        .then((response) => {
          this.$emit('updateList');
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 5000,
            color: 'success',
            message: response.data.message,
            messageIcon: 'mdi-check-circle',
          });
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 5000,
            color: 'error',
            message: response.data.error,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.close();
        });
    },
    refuseCompany() {
      PersonService.refuseCompany(this.selectedCompany.id, this.justification)
        .then((response) => {
          this.$emit('updateList');
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 5000,
            color: 'success',
            message: response.data.message,
            messageIcon: 'mdi-check-circle',
          });
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 5000,
            color: 'error',
            message: response.data.error,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.close();
        });
    },
  },
};
</script>

<style>

</style>
