var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.accept
        ? _c(
            "v-dialog",
            {
              attrs: { width: "400", persistent: "" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "v-toolbar",
                { staticClass: "headline", attrs: { color: "white" } },
                [_vm._v("\n      Deseja aceitar a solicitação?\n    ")]
              ),
              _c(
                "v-card",
                { staticClass: "text-xs-center", attrs: { width: "400" } },
                [
                  _c("span", [
                    _vm._v(
                      '\n        A empresa irá para a página "Empresas cadastradas".\n      '
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "mt-5 px-4" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            "v-btn",
                            { attrs: { flat: "" }, on: { click: _vm.close } },
                            [_vm._v("\n            Fechar\n          ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.acceptCompany },
                            },
                            [_vm._v("\n            Aceitar\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-dialog",
            {
              attrs: { width: "450", persistent: "" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "v-toolbar",
                { staticClass: "headline", attrs: { color: "white" } },
                [_vm._v("\n      Deseja recusar a solicitação?\n    ")]
              ),
              _c(
                "v-card",
                { staticClass: "text-xs-center px-4", attrs: { width: "450" } },
                [
                  _c("v-textarea", {
                    staticClass: "ma-0",
                    attrs: {
                      label: "Escreva uma justificativa",
                      name: "justification",
                      rules: _vm.rules,
                      maxlength: "500",
                      counter: "500",
                      "no-resize": "",
                      filled: "",
                    },
                    model: {
                      value: _vm.justification,
                      callback: function ($$v) {
                        _vm.justification = $$v
                      },
                      expression: "justification",
                    },
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-end": "" } },
                        [
                          _c(
                            "v-btn",
                            { attrs: { flat: "" }, on: { click: _vm.close } },
                            [_vm._v("\n            Fechar\n          ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                disabled: _vm.justification.length == 0,
                              },
                              on: { click: _vm.refuseCompany },
                            },
                            [_vm._v("\n            Recusar\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }